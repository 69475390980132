<template>
    <form>
    <div class="row mb-2">
        <div class="col-12">
            <img v-if="logo_data" @click="deleteLogo" :src="logo_data" class="img-fluid" style="max-width: 50%;margin: 15px 0; border: 1px dashed #ccc;" />
            <label class="m-0 d-block">Logo</label>
            <input id="input" class="form-control form-control-sm" type="file" @change="handleLogo">
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <label class="m-0">Header Data</label>
        </div>
        <div class="col-12">
            <textarea class="form-control form-control-sm" v-model="report_settings.header_data"></textarea>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <label class="m-0">Footer Data</label>
        </div>
        <div class="col-12">
            <textarea class="form-control form-control-sm" v-model="report_settings.footer_data"></textarea>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-12">
            <label class="m-0">Preview <input type="checkbox" class="form-control form-control-sm" v-model="report_settings.preview" /></label>
        </div>
    </div>
    </form>
    <a @click="saveReportSettings" class="btn btn-primary btn-sm">Save Report settings</a>
</template>
<script>
import { mapFields } from 'vuex-map-fields';

export default {
    name: 'Test Report Data',
    mixins: [ ],
    computed: {
        ...mapFields([
            'report_settings'
        ]),
        logo_data(){
            return this.$store.state.report_settings.logo_url;
        }
    },
    methods:{
        saveReportSettings(){
            localStorage.setItem('report_settings', JSON.stringify(this.$store.state.report_settings));
        },
        handleLogo(){
            var filesToUpload = document.getElementById('input').files;
            var file = filesToUpload[0];

            console.log(Math.round(file.size / 1024));

            if(Math.round((file.size / 1024)) > 50 ){
                alert('Max file size of 50 KB');
                return;
            }

            var img = document.createElement("img");
            var reader = new FileReader();
            var dataurl;

            var self = this;

            reader.onload = function(e) {
                img.src = e.target.result;
                dataurl = e.target.result;
                self.$store.state.report_settings.logo_url = dataurl;
            }

            reader.readAsDataURL(file);
        },
        deleteLogo(){
            if(confirm('Delete Logo?')){
                this.$store.state.report_settings.logo_url = null;
            }
        }

    },
    created() {
        let report_settings = JSON.parse(localStorage.getItem('report_settings')) || {};
        this.$store.commit('updateReportSettings', report_settings);
    }
}
</script>
<style>
label{
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .1em;
}
</style>