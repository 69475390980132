<template>
    <div style="max-height: 60vh; overflow-y: auto;" class="mb-3">
    <table class="table table-sm table-bordered" id="sensorList">
        <tr>
            <th>ID</th>
            <th>FS</th>
            <th>UM</th>
        </tr>
        <template v-for="item in user.sensor_library" :key="item.id">
        <tr>
            <td style="border: 1px dashed #efefef;"><strong>{{ item.id }}</strong></td>
            <td style="border: 1px dashed #efefef;">{{ item.fs }}</td>
            <td style="border: 1px dashed #efefef;">{{ this.$store.state.sensor.um_map[item.um] }}</td>
        </tr>
        <tr>
            <td class="text-right" colspan="3" style="border-top: 1px dashed #efefef;">
                <div class="btn-group" role="group">
                    <a @click="loadSensor(item.id)" class="btn btn-outline-primary btn-sm d-lg-none">Load</a>
                    <a @click="removeSensor(item.id)" class="btn btn-outline-primary btn-sm">Delete</a>
                    <a @click="exportSensor(item.id)" class="btn btn-outline-primary btn-sm">Export</a>
                </div>
            </td>
        </tr>
        </template>
    </table>
    </div>
    <a @click="$refs.fileInput.click()" class="btn btn-primary btn-sm">Import</a>
    <input ref="fileInput" type="file" @change="importSensor" style="display: none">
    <!-- <a @click="cloudSync" class="btn btn-primary">Cloud Sync</a> -->
</template>
<script>
import { UserMixin } from '@/mixin/user.js'
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'LibrarySensor',
  mixins: [UserMixin],
  data: function(){
    return {}
  },
  computed: {
    ...mapFields([
        'user'
    ]),
  },
  components:{
  },
  methods: {
      loadSensor(id){
          let sensor = this.$store.state.user.sensor_library[id];
          if(typeof sensor.extra.display_state != 'undefined'){
              this.$store.commit('setDisplay', sensor.extra.display_state);
          }
          if(typeof sensor.extra.testing_conditions != 'undefined'){
              this.$store.commit('setTestingProperty', {
                  key: 'testing_conditions',
                  value: sensor.extra.testing_conditions
              });
          }

          if(typeof sensor.interpolation_neg == 'undefined'){
            sensor.interpolation_neg = {
                P: 0,
                Q: 1,
                R: 0,
                S: 0
            };
          }

          if(typeof sensor.extra != 'undefined'){ //resettiamo gli extra
              sensor.extra = null;
          }
          this.$store.commit('setSensor', sensor);
      },
      removeSensor(id){
        if(!confirm('Are you sure?')) return;
        let sensor_library = JSON.parse(localStorage.getItem('sensor_library')) || {};
        let sensor_library_updated = {};
        for(let sensor_id in sensor_library){
            if(sensor_id != id){
                sensor_library_updated[sensor_id] = sensor_library[sensor_id];
            }
        }
        localStorage.setItem('sensor_library', JSON.stringify(sensor_library_updated));
        this.$store.commit('updateSensorLibrary');
      },
      exportSensor(id){
            let sensor_data = JSON.stringify(this.$store.state.user.sensor_library[id]);
            var element = document.createElement('a');
            element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(sensor_data));
            element.setAttribute('download', id+'.json');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
      },
      importSensor(event){
            // console.log(event.target.files);
            if (!event.target.files[0])
                return undefined;
            let file = event.target.files[0];
            let fr = new FileReader();
            var self = this;
            fr.onload = function() {
                let sensor = JSON.parse(fr.result);
                // console.log(sensor);
                if(typeof sensor.id == 'undefined'){
                    alert('Not valid file');
                    return;
                }
                let sensor_library = JSON.parse(localStorage.getItem('sensor_library')) || {};
                if(typeof sensor_library[sensor.id] != 'undefined'){
                    if(confirm('Replace existings calibration data for sensor '+sensor.id+'?')){
                        sensor_library[sensor.id] = sensor;
                        alert('Calibration data replaced');
                    }
                }else{
                    sensor_library[sensor.id] = sensor;
                }
                localStorage.setItem('sensor_library', JSON.stringify(sensor_library));
                self.$store.commit('updateSensorLibrary');
            };
            fr.readAsText(file);
            event.target.value = '';
            
      },
      onFileLoad(elementId, event) {
            document.getElementById(elementId).innerText = event.target.result;
        },
      onChooseFile(event, onLoadFileHandler) {
            if (typeof window.FileReader !== 'function')
                throw ("The file API isn't supported on this browser.");
            let input = event.target;
            if (!input)
                throw ("The browser does not properly implement the event object");
            if (!input.files)
                throw ("This browser does not support the `files` property of the file input.");
            if (!input.files[0])
                return undefined;
            let file = input.files[0];
            let fr = new FileReader();
            fr.onload = onLoadFileHandler;
            fr.readAsText(file);
        },
      cloudSync(){

      }
  },
  created: function() {
        this.$store.commit('updateSensorLibrary');
  }
}
</script>