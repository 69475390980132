<template>
    <div class="" style="max-width: 1300px; margin: auto;">
        <div class="row mt-2 mt-md-5">
            <div class="col-lg-6">
                <SensorTabs />
            </div>
            <div class="col-lg-6">
                <TestTabs />
            </div>
        </div>
    </div>
</template>
<script>
import SensorTabs from '@/components/SensorTabs.vue'
import TestTabs from '@/components/TestTabs.vue'

export default {
  name: 'Home',
  components: {
    SensorTabs, TestTabs
  },
  computed: {
    bleReceiving(){
      return  this.$store.state.ble.receiving;
    }
  }
}
</script>