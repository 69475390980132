<template>
    <DisplayMini />
    <div class="row">
        <div class="col-1 d-flex align-items-center justify-content-center">
            <span><i class="linearization-icon icofont-arrow-up"></i></span>
        </div>
        <div class="col-6">
            <div class="row">
                <div class="col-6 text-center"><label>Value</label></div>
                <div class="col-6 text-center"><label>Digits</label></div>
            </div>
            <div v-for="n in [0, 1, 2, 3, 4, 5]" :key="n" class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{ um_str }}</span>
                </div>
                <input type="number" class="form-control" @change="sensor.linearization[n][0]=(sensor.linearization[n][0] != 0 ? Math.abs(sensor.linearization[n][0]) : '')" v-model="sensor.linearization[n][0]" :readonly="n==0">
                <div class="input-group-append">
                    <span class="input-group-text cursor-pointer" @click="sensor.linearization[n][1]=ble.raw_value">U</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.linearization[n][1]">
            </div>
        </div>
        <div class="col-5 d-flex flex-column justify-content-end">
            <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">P</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.interpolation.P">
            </div>
            <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">Q</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.interpolation.Q">
            </div>
            <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">R</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.interpolation.R">
            </div>
            <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">S</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.interpolation.S">
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-1 d-flex align-items-center justify-content-center">
            <span><i class="linearization-icon icofont-arrow-down"></i></span>
        </div>
        <div class="col-6">
            <div v-for="n in [0, 1, 2, 3, 4, 5]" :key="n" class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">{{ um_str }}</span>
                </div>
                <input type="number" class="form-control" @change="sensor.linearization_neg[n][0]=(sensor.linearization_neg[n][0] != 0 ? Math.abs(sensor.linearization_neg[n][0])*-1 : '')" v-model="sensor.linearization_neg[n][0]" :readonly="n==0">
                <div class="input-group-append">
                    <span class="input-group-text cursor-pointer" @click="sensor.linearization_neg[n][1]=ble.raw_value">U</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.linearization_neg[n][1]">
            </div>
            <div class="row">
                <div class="col-6 text-center"><label>Value</label></div>
                <div class="col-6 text-center"><label>Digits</label></div>
            </div>
        </div>
        <div class="col-5">
            <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">P</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.interpolation_neg.P">
            </div>
            <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">Q</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.interpolation_neg.Q">
            </div>
            <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">R</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.interpolation_neg.R">
            </div>
            <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                    <span class="input-group-text">S</span>
                </div>
                <input type="number" class="form-control" v-model="sensor.interpolation_neg.S">
            </div>
        </div>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields';
import { SensorMixin } from '@/mixin/sensor.js'
import DisplayMini from '@/components/DisplayMini.vue'

export default {
    name: 'Linearization',
    mixins: [ SensorMixin ],
    components: { DisplayMini },
    computed: {
        ...mapFields([
            'sensor', 'ble'
        ]),
        um_str: function(){
            return this.$store.state.sensor.um_map[this.$store.state.sensor.um];
        }
        
    },
    methods: {
        
    }
}
</script>
<style language="sass">
.cursor-pointer{
    cursor: pointer;
}
.linearization-icon.icofont-arrow-down,
.linearization-icon.icofont-arrow-up{
    font-size: 2em;
}
</style>