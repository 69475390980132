<template>
  
  <div class="mb-3">
    <nav>
        <div class="nav nav-tabs" id="nav-tab-test" role="tablist">
            <a v-if="bleReceiving" :class="'nav-item nav-link '+(bleReceiving ? 'active' : '')" id="nav-TestArea-tab" data-toggle="tab" href="#nav-TestArea" role="tab" aria-controls="nav-TestArea" aria-selected="true">Generate New Test</a>
            <a :class="'nav-item nav-link '+(bleReceiving ? '' : 'active')" id="nav-TestArchive-tab" data-toggle="tab" href="#nav-TestArchive" role="tab" aria-controls="nav-TestArchive" aria-selected="true">Test Archive</a>
            <a class="nav-item nav-link" v-if="this.$store.state.test_details" id="nav-TestArchive-tab" data-toggle="tab" href="#nav-TestDetail" role="tab" aria-controls="nav-TestDetail" aria-selected="true" ref="testDetailTab">{{ testDetailName }}</a>
            <a class="nav-item nav-link" id="nav-TestReportData-tab" data-toggle="tab" href="#nav-TestReportData" role="tab" aria-controls="nav-TestReportData" aria-selected="true" ref="TestReportData">Report Settings</a>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContentDevice">
        <div v-if="bleReceiving" :class="'tab-pane fade py-2 '+(bleReceiving ? 'show active' : '')" id="nav-TestArea" role="tabpanel" aria-labelledby="nav-TestArea-tab">
          <div class="d-md-none"><DisplayMini /></div>
          <TestChart v-if="bleReceiving" />
          <TestArea />
        </div>
        <div :class="'tab-pane fade py-2 '+(bleReceiving ? '' : 'show active')" id="nav-TestArchive" role="tabpanel" aria-labelledby="nav-TestArchive-tab">
          <TestArchive />
        </div>
        <div class="tab-pane fade py-2" v-if="this.$store.state.test_details" id="nav-TestDetail" role="tabpanel" aria-labelledby="nav-TestDetail-tab">
          <TestDetail v-if="this.$store.state.test_details" :test="testDetail" />
        </div>
        <div class="tab-pane fade py-2" id="nav-TestReportData" role="tabpanel" aria-labelledby="nav-TestReportData-tab">
          <TestReportSettings />
        </div>
    </div>
  </div>
</template>
<script>
import TestChart from '@/components/TestChart.vue'
import TestArea from '@/components/TestArea.vue'
import TestArchive from '@/components/TestArchive.vue'
import TestDetail from '@/components/TestDetail.vue'
import TestReportSettings from '@/components/TestReportSettings.vue'
import DisplayMini from '@/components/DisplayMini.vue'


export default {
  name: 'DeviceTabs',
  components: {
    TestChart, TestArea, TestArchive, TestDetail, TestReportSettings, DisplayMini
  },
  computed: {
    testDetail(){
      return this.$store.state.test_details
    },
    testDetailName(){
      return this.$store.state.test_details.name;
    },
    bleReceiving(){
      return  this.$store.state.ble.receiving;
    }
  },
  methods: {
  },
}
</script>
<style>
nav{
    overflow: auto;
}
.nav.nav-tabs{
    flex-wrap: nowrap;
}
.nav-tabs .nav-link{
    white-space: nowrap;
}
</style>