<template>
<div :class="displayClass()" id="card-display">
        <div class="card-body">
            <div class="display">
                <div class="row">
                    <!-- <div class="col-4"><span class="um">{{ um }}</span></div> -->
                    <div class="col-12" v-if="sensor.custom_conversion.formula" :style="{ fontSize: display.display_size/2 + 'em' }">
                        {{ sensor.custom_conversion.um }} {{ custom_conversion_value }}
                    </div>
                </div>
                <div class="row" v-if="true || !display.on_peak_plus && !display.on_peak_minus">
                    <div class="col-10 display_value" :style="{ fontSize: display.display_size + 'em' }">{{ um }} {{ main_display }}</div>
                    <div class="col-2"><span class="direction"><i :class="directionClass"></i></span></div>
                </div>
            </div>
            <div class="display peak mb-2" v-if="display.on_peak_plus || display.on_peak_minus">
                <div class="row" style="line-height: 1">
                    <div class="col-6" v-if="display.on_peak_plus">
                        <small>PEAK+</small>
                        <div :style="{ fontSize: display.display_size/1.5 + 'em' }">{{ peak_plus_value }}</div>
                        <div v-if="sensor.custom_conversion.formula" :style="{ fontSize: display.display_size/3 + 'em' }">{{ sensor.custom_conversion.um }} {{ peak_plus_value_converted }}</div>
                    </div>
                    <div class="col-6" v-if="display.on_peak_minus">
                        <small>PEAK-</small>
                        <div :style="{ fontSize: display.display_size/1.5 + 'em' }">{{ peak_minus_value }}</div>
                        <div v-if="sensor.custom_conversion.formula" :style="{ fontSize: display.display_size/3 + 'em' }">{{ sensor.custom_conversion.um }} {{ peak_minus_value_converted }}</div>
                    </div>
                </div>
            </div>
            <div v-if="secondary_display" class="calculated">
                {{ calculated_val }}{{ um }}
            </div>
            <div class="raw_val">
                <span  v-if="!safe_mode" class="mr-2">U={{ raw_val }}</span><span v-if="display.on_tare">TARE={{ tare }} {{ um }}</span>
            </div>
            <div class="functions mt-3 pt-3" style="border-top: 1px solid rgba(0,0,0, .2)">
                <button class="btn mr-1 mb-1" :class="holdClass()" @click="toggleHold()">HOLD</button>
                <button class="btn mr-1 mb-1" :class="peakPlusClass()" @click="togglePeakPlus()">PEAK+</button>
                <button class="btn mr-1 mb-1" :class="peakMinusClass()" @click="togglePeakMinus()">PEAK-</button>
                <button class="btn mr-1 mb-1" :class="tareClass()" @click="toggleTare()">TARE</button>
            </div>
            <div class="frequency">
                <button class="btn btn-sm mr-1 mb-1" :class="freqClass(3)" @click="changeFreq(3)">2.5<small>Hz</small></button>
                <button class="btn btn-sm mr-1 mb-1" :class="freqClass(19)" @click="changeFreq(19)">5<small>Hz</small></button>
                <button class="btn btn-sm mr-1 mb-1" :class="freqClass(35)" @click="changeFreq(35)">10<small>Hz</small></button>
                <button class="btn btn-sm mr-1 mb-1" :class="freqClass(67)" @click="changeFreq(67)">25<small>Hz</small></button>
                <button class="btn btn-sm mr-1 mb-1" :class="freqClass(99)" @click="changeFreq(99)">50<small>Hz</small></button>
                <button class="btn btn-sm mr-1 mb-1 d-none d-md-inline-block" :class="freqClass(130)" @click="changeFreq(130)">100<small>Hz</small></button>
                <button class="btn btn-sm mr-1 mb-1 d-none d-md-inline-block" :class="freqClass(146)" @click="changeFreq(146)">500<small>Hz</small></button>
                <button class="btn btn-sm mr-1 mb-1 d-none d-md-inline-block" :class="freqClass(161)" @click="changeFreq(161)">1<small>kHz</small></button>
            </div>
            <div class="d-none d-md-block display_params mt-3">
                <div class="row">
                    <div class="col-4">
                        <label class="m-0">Resolution = {{ display.resolution_points }}</label>
                        <input class="form-control p-0" type="range" min="0" max="10" v-model="display.resolution_points" />
                    </div>
                    <div class="col-4">
                        <label class="m-0">Filtering = {{ filtering_state }}</label>
                        <input class="form-control p-0" type="range" min="0" max="100" v-model="display.filter_lenght" />
                    </div>
                    <div class="col-4">
                        <label class="m-0">D. Size = {{ display.display_size }}</label>
                        <input class="form-control p-0" type="range" min="1" max="5" step=".1" v-model="display.display_size" />
                    </div>
                </div>
            </div>
            <div class="d-md-none display_params mt-3">
                <div class="row no-gutters">
                    <div class="col-5">
                        <label class="m-0">Filtering = {{ filtering_state }}</label>
                    </div>
                    <div class="col-4">
                        <input class="form-control p-0" type="range" min="0" max="100" v-model="display.filter_lenght" />
                    </div>
                    <div class="col-5">
                        <label class="m-0">Resolution = {{ display.resolution_points }}</label>
                    </div>
                    <div class="col-4">
                        <input class="form-control p-0" type="range" min="0" max="10" v-model="display.resolution_points" />
                    </div>
                    <div class="col-5">
                        <label class="m-0">D. Size = {{ display.display_size }}</label>
                    </div>
                    <div class="col-4">
                        <input class="form-control p-0" type="range" min="1" max="5" step=".1" v-model="display.display_size" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { Resolver } from 'formula-resolver'
// const resolver = new Resolver();
import { BleMixin } from '@/mixin/ble.js'
import { SensorMixin } from '@/mixin/sensor.js'
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Display',
  mixins: [ BleMixin, SensorMixin ],
  computed: {
      ...mapFields([
            'display', 'sensor'
        ]),
        safe_mode(){
            return this.$store.state.safe_mode;
        },
      raw_val(){
          return this.$store.state.ble.raw_value;
      },
      um(){
          return this.$store.state.sensor.um_map[this.$store.state.sensor.um];
      },
      tare(){
          return this.$store.state.display.tare_value;
      },
      filtering_state(){
          if(this.$store.state.display.filter_lenght > 3){
              return this.$store.state.display.filter_lenght;
          }else return 'No'
      },
      secondary_display(){
          return false;
        //   return this.$store.state.display.on_hold || this.$store.state.display.on_peak || this.$store.state.display.on_tare;
      },
      display_style(){
          return 'font-size: '+this.$store.state.display.display_size+'em;';
      },
      custom_conversion_value(){
            // let x = this.main_display.replace(',','').replace(' ','')*1;

            // let formula = this.$store.state.sensor.custom_conversion.formula.replace(/@/g,x);
            // formula = resolver.resolve(formula).result;
            
            // return this._number_format(formula, this.$store.state.sensor.custom_conversion.resolution, '.', ' ');

            return this.$store.state.converted_value_formatted;
      },
      peak_plus_value(){
          return this._number_format(this.$store.state.display.peak_plus_value, this.$store.state.display.resolution_points, '.', ' ');
      },
      peak_plus_value_converted(){
          let conversion = this.get_value_converted(this.$store.state.display.peak_plus_value, this.$store.state.sensor);
            return this._number_format(conversion, this.$store.state.sensor.custom_conversion.resolution);
      },
      peak_minus_value(){
          return this._number_format(this.$store.state.display.peak_minus_value, this.$store.state.display.resolution_points, '.', ' ');
      },
      peak_minus_value_converted(){
            let conversion = this.get_value_converted(this.$store.state.display.peak_minus_value, this.$store.state.sensor);
            return this._number_format(conversion, this.$store.state.sensor.custom_conversion.resolution);
      },
      directionClass(){
          if(this.$store.state.display_value >= 0){
              return 'icofont-arrow-up';
          }else{
              return 'icofont-arrow-down';
          }
      },
      bleFreq(){
          return this.$store.state.ble.freq;
      }
  },
  methods: {
      displayClass(){
          return this.$store.state.sensor.overloaded ? 'overload card mb-3' : 'card mb-3';
      },
      freqClass(index){
          return this.$store.state.ble.freq == index ? 'btn-primary' : 'btn-outline-secondary';
      },
      changeFreq(index){
        this.$store.commit('setBleProperty', {
            key: 'freq',
            value: index
        });
      },
      toggleHold(){
          this.$store.commit('setDisplayProperty', {
                key: 'hold_value',
                value: this.$store.state.display_value
            })
          this.$store.commit('setDisplayProperty', {
              key: 'on_hold',
              value: !this.$store.state.display.on_hold
          });
          if(this.$store.state.display.on_hold){
            this.$store.commit('setDisplayProperty', {
                key: 'on_peak',
                value: false
            })
            this.$store.commit('setDisplayProperty', {
                key: 'peak_value',
                value: 0
            })
            this.$store.commit('setDisplayProperty', {
                key: 'on_peak_plus',
                value: false
            })
            this.$store.commit('setDisplayProperty', {
                key: 'peak_plus_value',
                value: 0
            })
            this.$store.commit('setDisplayProperty', {
                key: 'on_peak_minus',
                value: false
            })
            this.$store.commit('setDisplayProperty', {
                key: 'peak_minus_value',
                value: 0
            })
          }else{
            this.$store.commit('setDisplayProperty', {
                key: 'hold_value',
                value: 0
            })
          }
      },
      holdClass(){
          return this.$store.state.display.on_hold ? 'btn-primary' : 'btn-outline-secondary';
      },
      togglePeak(){
          var self = this;
          this.$store.commit('setDisplayProperty', {
              key: 'on_peak',
              value: !this.$store.state.display.on_peak
          })
          if(this.$store.state.display.on_peak){
            this.$store.commit('setDisplayProperty', {
                key: 'peak_value',
                value: self.main_display.replace(',','').replace(' ','')*1
            })
            this.$store.commit('setDisplayProperty', {
                key: 'on_hold',
                value: false
            })
            this.$store.commit('setDisplayProperty', {
                key: 'hold_value',
                value: 0
            })
          }else{
            this.$store.commit('setDisplayProperty', {
                key: 'peak_value',
                value: 0
            })
          }
      },
      togglePeakPlus(){
          this.$store.commit('setDisplayProperty', {
              key: 'on_peak_plus',
              value: !this.$store.state.display.on_peak_plus
          })
          if(this.$store.state.display.on_peak_plus){
            this.$store.commit('setDisplayProperty', {
                key: 'peak_plus_value',
                value: this.$store.state.display_value
            })
            this.$store.commit('setDisplayProperty', {
                key: 'on_hold',
                value: false
            })
            this.$store.commit('setDisplayProperty', {
                key: 'hold_value',
                value: 0
            })
          }else{
            this.$store.commit('setDisplayProperty', {
                key: 'peak_plus_value',
                value: 0
            })
          }
      },
      togglePeakMinus(){
          this.$store.commit('setDisplayProperty', {
              key: 'on_peak_minus',
              value: !this.$store.state.display.on_peak_minus
          })
          if(this.$store.state.display.on_peak_minus){
            this.$store.commit('setDisplayProperty', {
                key: 'peak_minus_value',
                value: this.$store.state.display_value
            })
            this.$store.commit('setDisplayProperty', {
                key: 'on_hold',
                value: false
            })
            this.$store.commit('setDisplayProperty', {
                key: 'hold_value',
                value: 0
            })
          }else{
            this.$store.commit('setDisplayProperty', {
                key: 'peak_minus_value',
                value: 0
            })
          }
      },
      peakPlusClass(){
          return this.$store.state.display.on_peak_plus ? 'btn-primary' : 'btn-outline-secondary';
      },
      peakMinusClass(){
          return this.$store.state.display.on_peak_minus ? 'btn-primary' : 'btn-outline-secondary';
      },
      toggleTare(){
          var self = this;
          this.$store.commit('setDisplayProperty', {
              key: 'on_tare',
              value: !this.$store.state.display.on_tare
          })
          this.$store.commit('setDisplayProperty', {
              key: 'tare_value',
              value: self.main_display.replace(',','').replace(' ','')*1
          })
          this.$store.commit('setDisplayProperty', {
              key: 'tare_raw_value',
              value: self.raw_val
          })
      },
      tareClass(){
          return this.$store.state.display.on_tare ? 'btn-primary' : 'btn-outline-secondary';
      }
  },
  watch: {
    bleFreq (newVal, oldVal) {
      if(newVal != oldVal){
        this.setFreq(newVal);
      }
      
    }
  }
}
</script>
<style lang="scss">
@media screen and (max-width: 767px){
    #card-display{
        margin-left: -15px;
        margin-right: -15px;
    }
}
.um{
    font-weight: 200;
    font-size: 20px;
}
.display_value{
    font-size: 2.5em;
    font-family: 'Roboto Mono', monospace;
}
.direction{
    font-size: 3em;
}
</style>