<template>
<div :class="displayClass()" id="card-display-mini">
        <div class="card-body">
            <div class="row">
                <div class="col-sm-8">
                    <div class="display_value">{{ um }} {{ display_value }}</div>
                </div>
                <div class="col-sm-4">
                    <div v-if="sensor.custom_conversion.formula">
                        {{ sensor.custom_conversion.um }} {{ custom_conversion_value }}
                    </div>
                    <div class="on_tare"  v-if="display.on_tare">
                        TARE={{ tare }} {{ um }}
                    </div>
                    <div class="raw_val">
                        U={{ raw_val }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Display Mini',
  mixins: [  ],
  computed: {
      ...mapFields([
            'display', 'sensor'
        ]),
        safe_mode(){
            return this.$store.state.safe_mode;
        },
      raw_val(){
          return this.$store.state.ble.raw_value;
      },
      um(){
          return this.$store.state.sensor.um_map[this.$store.state.sensor.um];
      },
      tare(){
          return this.$store.state.display.tare_value;
      },
      display_value(){
          return this.$store.state.display_value;
      },
      custom_conversion_value(){
        return this.$store.state.converted_value_formatted;
      }
  },
  methods: {
      displayClass(){
          return this.$store.state.sensor.overloaded ? 'overload card mb-3' : 'card mb-3';
      }
  },
}
</script>
<style>
.um{
    font-weight: 200;
    font-size: 20px;
}
.display_value{
    font-size: 2.5em;
    font-family: 'Roboto Mono', monospace;
}
.direction{
    font-size: 3em;
}

</style>