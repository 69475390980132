<template>
    <form>
        <div class="row">
            <div class="col-7">
                <div class="input-group input-group-sm mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text">ID</span>
                    </div>
                    <input type="text" class="form-control" :disabled="safeMode" v-model="sensor.id">
                </div>
                <div class="input-group input-group-sm mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text">FS</span>
                    </div>
                    <input type="number" step="0.001" :disabled="safeMode" class="form-control" v-model="sensor.fs">
                </div>
                <div class="input-group input-group-sm mb-1">
                    <div class="input-group-prepend">
                        <span class="input-group-text">UM</span>
                    </div>
                    <select v-model="sensor.um" class="form-control" :disabled="safeMode">
                        <option v-for="(item, index) in sensor.um_map" v-bind:key="index" :value="index">
                            {{ item }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-5">
                <a @click="readChipInfo" class="btn btn-sm btn-block btn-outline-primary mb-1">Read Chip</a>
                <!-- <a @click="reinitCell" class="btn btn-sm btn-block btn-outline-primary mb-1">Init cell</a> -->
                <a v-if="!safeMode" @click="saveSensorData" class="btn btn-sm btn-block btn-outline-danger mb-1">Overwrite Chip</a>
                <a v-if="safeMode" @click="setAdvancedMode" class="btn btn-sm btn-block btn-outline-primary mb-1">Enable Advanced Mode</a>
                <a v-if="!safeMode" @click="backToSafeMode" class="btn btn-sm btn-block btn-outline-primary mb-1">Back to Safe Mode</a>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-12"><label>Additional Custom Conversion</label></div>
            <div class="col-xs-4 col-md-3">
                <div class="input-group input-group-sm">
                    <input type="text" class="form-control" v-model="sensor.custom_conversion.um">
                    <div class="input-group-append">
                        <span class="input-group-text">=</span>
                    </div>
                </div>
                <label>UM</label>
            </div>
            <div class="col-xs-8 col-md-9">
                <input type="text" class="form-control form-control-sm border-left-0" v-model="sensor.custom_conversion.formula">
                <label>Formula</label>
                <small class="text-info"> (use "@" as calculated value)</small>                
            </div>
        </div>
        <div class="row no-gutters" v-if="sensor.custom_conversion.formula">
            <div class="col-2">
                <label class="m-0">Res = {{ sensor.custom_conversion.resolution }}</label>
            </div>
            <div class="col-4">
                <input class="form-control form-control-sm p-0" type="range" min="0" max="10" step="1" v-model="sensor.custom_conversion.resolution" />
            </div>
        </div>
    </form>
    <a @click="saveSensorToLibrary" class="btn btn-primary">Save to Library</a>
</template>
<script>
import { UserMixin } from '@/mixin/user.js'
import { mapFields } from 'vuex-map-fields';
import { BleMixin } from '@/mixin/ble.js'
import { mapState } from 'vuex';
import { SensorMixin } from '@/mixin/sensor.js'

export default {
    name: 'Sensor Data',
    mixins: [ UserMixin, BleMixin, SensorMixin ],
    computed: {
        ...mapFields([
            'sensor', 'user'
        ]),
        ...mapState(['ble']),
        safeMode(){
            return  this.$store.state.safe_mode;
        },
        bleReceiving(){
            return  this.$store.state.ble.receiving;
        },
        userFactory(){
            return this.$store.state.user.role == 'developer';
        }
    },
    methods:{
        saveSensorData(){
            if(!confirm('You are writing to the sensor chip permanently. Are you sure?')) return;
            let buffer = this._appendBuffer(Uint8Array.of(4), this.str2Ascii(this.sensor.id, 10));
            buffer = this._appendBuffer(buffer, this.num2Byte(this.sensor.fs, 4));
            // console.log('um:', this.sensor.um);
            // buffer = this._appendBuffer(buffer, this.str2Ascii(this.sensor.um, 4));
            buffer = this._appendBuffer(buffer, this.num2Byte(this.sensor.um, 4));
            // console.log(buffer);
            this.write(buffer);
            this.$store.commit('setSafeMode', 1);
        },
        readChipInfo(){
            console.log('reading chip info');
            let self=this;
            this.$store.commit('dataviewOnceListener', function(dataView){
                console.log('chip info dataview', dataView.getInt8(), dataView);
                if(dataView.getInt8() != 2) return false;
                var sensor_id = self.ascii2Str(dataView, 1, 11);
                self.$store.commit('setSensorProperty', {
                    key: 'id',
                    value: sensor_id
                });
                //cerchiamo l'id        
                let sensor_library = JSON.parse(localStorage.getItem('sensor_library')) || {};        
                if(sensor_library[sensor_id]){
                    let sensor = sensor_library[sensor_id];
                    if(typeof(sensor.extra) !== 'undefined'){
                        if(typeof sensor.extra.display_state != 'undefined'){
                            self.$store.commit('setDisplay', sensor.extra.display_state);
                        }
                        if(typeof sensor.extra.testing_conditions != 'undefined'){
                            self.$store.commit('setTestingProperty', {
                                key: 'testing_conditions',
                                value: sensor.extra.testing_conditions
                            });
                        }
                        if(typeof sensor.extra.freq != 'undefined' && sensor.extra.freq != self.$store.state.ble.freq ){
                            self.$store.commit('setBleProperty', {
                                key: 'freq',
                                value: sensor.extra.freq
                            });
                        }
                        sensor.extra = null;
                    }
                    if(typeof sensor.interpolation_neg == 'undefined'){
                        sensor.interpolation_neg = {
                            P: 0,
                            Q: 1,
                            R: 0,
                            S: 0
                        };
                    }
                    self.$store.commit('setSensor', sensor);
                }else{
                    //reset delle informazioni di calibrazione
                    var reset_sensor = JSON.parse(JSON.stringify(self.$store.state.sensor_reset));
                    reset_sensor.id = sensor_id;
                    console.log('No sensor found in Library, reset', reset_sensor);
                    self.$store.commit('setSensor', reset_sensor);
                }

                self.$store.commit('setSensorProperty', {
                    key: 'fs',
                    value: self.byte2Num(dataView.buffer.slice(11, 15))
                });
                self.$store.commit('setSensorProperty', {
                    key: 'um',
                    // value: self.ascii2Str(dataView, 21, 25)
                    value: self.byte2Num(dataView.buffer.slice(15, 19))
                });
            })
            this.write(Uint8Array.of(2)).then(function(){
                console.log('then chip info done')
                self;
            });
        },
        reinitCell(){
            this.write(Uint8Array.of(3)).then(function(){
                console.log('init cell done')
                self;
            });
        },
        setAdvancedMode(){
            let pwd = 'Dea1907';
            let pwd_suggest = this.$store.state.user.role == 'developer' ? pwd : '';
            var person = prompt("Please enter password", pwd_suggest );
            if( person == pwd ){
                this.$store.commit('setSafeMode', 0);
            }
        },
        backToSafeMode(){
            this.$store.commit('setSafeMode', 1);
        },
        saveSensorToLibrary(){
            let sensor_library = JSON.parse(localStorage.getItem('sensor_library')) || {};
            if(!this.$store.state.sensor.id){
                alert('Please specific a sensor ID');
                return null;
            }

            // se viene fatta la calibrazione dello zero solo in un verso
            // va copiata precisa nella'ltro verso
            if(this.$store.state.sensor.linearization[0][1] && !this.$store.state.sensor.linearization_neg[0][1]){
                this.$store.state.sensor.linearization_neg[0][1] = this.$store.state.sensor.linearization[0][1];
            }
            if(this.$store.state.sensor.linearization_neg[0][1] && !this.$store.state.sensor.linearization[0][1]){
                this.$store.state.sensor.linearization[0][1] = this.$store.state.sensor.linearization_neg[0][1];
            }

            var sensor = JSON.parse(JSON.stringify(this.$store.state.sensor));

            sensor.last_update = new Date().toJSON().slice(0, 19).replace('T', ' ');
            sensor.extra = {
                display_state: this.$store.state.display,
                testing_conditions: this.$store.state.testing.testing_conditions,
                freq: this.$store.state.ble.freq
            }
            sensor_library[this.$store.state.sensor.id] = sensor;
            console.log(sensor_library);
            localStorage.setItem('sensor_library', JSON.stringify(sensor_library));
            this.$store.commit('updateSensorLibrary');
            this.$store.commit('setSafeMode', 1);
        }

    },
    created() {
        if(this.$store.state.ble.receiving){
            this.readChipInfo();
        }
        var sensor_reset = JSON.parse(JSON.stringify(this.$store.state.sensor));
        console.log('Setting sensor_reset from sensor', sensor_reset);
        this.$store.commit('setSensorReset', sensor_reset);
        this.unsubscribe = this.$store.subscribe((mutation) => {
            var self=this;
            if ( mutation.type === 'setBleProperty' 
                && mutation.payload.key == 'receiving'
                && mutation.payload.value == true
            ) {
                // setTimeout(function(){self.readChipInfo()}, 500);
                self.readChipInfo();
                self;
            }
        });
    },
    beforeUnmount() {
        this.unsubscribe();
    },
}
</script>
<style>
label{
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: .1em;
}
</style>