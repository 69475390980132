import { mapState } from 'vuex'
import { Resolver } from 'formula-resolver'
const resolver = new Resolver();

export const SensorMixin = {
    data(){
        return {
        }
    },
    computed: {
        main_display(){
            var val = this.value_calculation();
            var display = this.$store.state.display;
            var ret = val;
            var tare_val = 0;

            if(display.on_tare){
                // if(!display.tare_value){
                //     let tare = val;
                //     if(display.hold_value){
                //         tare = display.hold_value;
                //     }else if(display.peak_value){
                //         tare = display.peak_value;
                //     }
                //     this.$store.commit('setDisplayProperty', {
                //         key: 'tare_value',
                //         value: tare
                //     });
                // }
                tare_val = this.$store.state.display.tare_value;
                val -= tare_val;
            }else{
                if(display.tare_value){
                    this.$store.commit('setDisplayProperty', {
                        key: 'tare_value',
                        value: 0
                    });
                }
            }
            
            if( display.on_hold && display.hold_value){
                ret = display.hold_value;
            }

            // if( display.on_peak ){
            //     if(display.peak_value > val) ret= display.peak_value;
            //     else{
            //         this.$store.commit('setDisplayProperty', {
            //             key: 'peak_value',
            //             value: val
            //         });
            //         ret = val;
            //     }
            // }
            // // console.log(display.on_peak_plus)
            if( display.on_peak_plus ){
                if(display.peak_plus_value < ( val + tare_val )) {
                    this.$store.commit('setDisplayProperty', {
                        key: 'peak_plus_value',
                        value: ( val + tare_val )
                    });
                }
            }
            if( display.on_peak_minus ){
                if(display.peak_minus_value > ( val + tare_val )){
                    this.$store.commit('setDisplayProperty', {
                        key: 'peak_minus_value',
                        value: ( val + tare_val )
                    });
                }
            }

            //overload
            if(Math.abs(ret)*.95 > this.$store.state.sensor.fs && !this.$store.state.sensor.overloaded){
                this.$store.commit('setSensorProperty', {
                    key: 'overloaded',
                    value: true
                });
            }else if(Math.abs(ret)*.95 < this.$store.state.sensor.fs && this.$store.state.sensor.overloaded){
                this.$store.commit('setSensorProperty', {
                    key: 'overloaded',
                    value: false
                });
            }

            this.$store.commit('setDisplayValue', ret);

            var ret_formatted = this._number_format(ret, this.$store.state.display.resolution_points, '.', ' ');
            this.$store.commit('setDisplayValue', ret_formatted);

            return ret_formatted;
        },
        calculated_val(){
            return this.value_calculation();
        },
        calculated_val_formatted(){
            var val = this.value_calculation();

            return this._number_format(val, this.$store.state.display.resolution_points);
        },
        um(){
            return this.$store.state.sensor.um;
        },
        um_str(){
            return this.$store.state.sensor.um_map[this.$store.state.sensor.um];
        },
        um_conversion(){
            return this.$store.state.sensor.custom_conversion.um;
        },
        ...mapState([])
    },
    methods: {
        value_calculation: function(){

            if(!this.$store.state.display.on_hold){
                var U = this.$store.state.ble.raw_value;

                if( U && this.$store.state.display.filter_lenght > 2 ){
                    //stiamo adottando il filtro
                    let filter_buffer = JSON.parse(JSON.stringify(this.$store.state.display.filter_buffer));
                    // console.log(filter_buffer.length);
                    if( filter_buffer.length < this.$store.state.display.filter_lenght){
                        filter_buffer.push(U);
                        this.$store.commit('setDisplayProperty', {
                            key: 'filter_buffer',
                            value: filter_buffer
                        });
                    }else if(filter_buffer.length > this.$store.state.display.filter_lenght){
                        this.$store.commit('setDisplayProperty', {
                            key: 'filter_buffer',
                            value: []
                        });
                    }else{//abbiamo riempito il buffer
                        // console.log('pre', filter_buffer);
                        filter_buffer.shift();
                        filter_buffer.push(U);
                        // console.log('post', filter_buffer);
                        this.$store.commit('setDisplayProperty', {
                            key: 'filter_buffer',
                            value: filter_buffer
                        });
                        // console.log(filter_buffer);
                        U = ( filter_buffer.reduce((a, b) => a + b) - Math.min(...filter_buffer) - Math.max(...filter_buffer) ) / (filter_buffer.length - 2);
                        // console.log(U);
                    }
                    
                }

                U = this.get_value_calculation(U, this.$store.state.sensor);

                this.$store.state.calculated_value = U;
                this.$store.state.calculated_value_formatted = this._number_format(U, this.$store.state.display.resolution_points);
                this.$store.state.calculated_value_timestamp = this.$store.state.ble.raw_value_timestamp;
            }else{
                U = this.$store.state.display.hold_value;
            }
            var tare_value = 0;
            if(this.$store.state.display.on_tare){
                tare_value = this.$store.state.display.tare_value;
                U -= tare_value;
            }

            //conversioni custom
            if(this.$store.state.sensor.custom_conversion.formula){
                let formula = this.$store.state.sensor.custom_conversion.formula.replace(/@/g, this.$store.state.display_value);
                formula = resolver.resolve(formula).result;
                this.$store.commit('setConvertedVal', formula);
                this.$store.state.converted_value_formatted = this._number_format(formula, this.$store.state.sensor.custom_conversion.resolution, '.', ' ');
            }


            return U;
        },
        get_value_calculation: function(U, sensor){ 
            //stessa funzione di cui sopra ma riceve la U e i parametri di calibrazione
            //non usa filtri elettronici

            let lin, lin_other;
            let lin_pos = JSON.parse(JSON.stringify(sensor.linearization))
            let lin_neg = JSON.parse(JSON.stringify(sensor.linearization_neg));

            if( lin_neg[0][1] != lin_pos[0][1]  ){
                //se è impostato anche uno zero negativo
                //siamo con un sensore che lavora su due versi (esempio trazione/compressione)
                
                //traslo la direzione che ha lo zero maggiore
                //in modo che gli zero coincidano
                if(lin_neg[0][1] < lin_pos[0][1]){
                    let delta = lin_pos[0][1] - lin_neg[0][1];
                    for(i=1; i<=5; i++){
                        lin_pos[i][1] = lin_pos[i][1] - delta;
                    }
                }else{
                    let delta = lin_neg[0][1] - lin_pos[0][1];
                    for(i=1; i<=5; i++){
                        lin_neg[i][1] = lin_neg[i][1] - delta;
                    }
                }                
            }
            //ora cerchiamo di capire in che direzione stiamo lavorando
            lin = lin_pos;
            lin_other = lin_neg;
            //interpolazione
            var I = sensor.interpolation;

            if( U >= lin[0][1] ){
                lin = lin_pos;
                lin_other = lin_neg;
            }else if( U < lin[0][1] ){
                lin = lin_neg;
                lin_other = lin_pos;
                if(typeof sensor.interpolation_neg != 'undefined'){
                    I = sensor.interpolation_neg;
                }
            }

            var tare = lin[0][1];
            var k = lin[1][0] / ( lin[1][1] - tare );
            if(!k){ //deve prendere almeno un k
                k = lin_other[1][0] / ( lin_other[1][1] - tare );
            }
            var i;

            for(i=2; i<=6; i++){
                if(typeof lin[i] != 'undefined' && lin[i][0] != '' && lin[i][0] > 0 && lin[i][1] <= U){
                    k = lin[i][0] / ( lin[i][1] - tare );
                }
            }
            
            U = ( U - tare ) * k;

            // F=P+Q*U+R*U2+S*U3
            U = +I.P +( +I.Q * U ) + ( +I.R * Math.pow(U, 2) ) + ( +I.S * Math.pow(U, 3) );

            return U;
        },
        get_value_converted(U, sensor){
            if(sensor.custom_conversion.formula){
                let formula = sensor.custom_conversion.formula.replace(/@/g,U);
                return resolver.resolve(formula).result;
            }
            return U;
        },
        testToRecords(test){

            test = JSON.parse(JSON.stringify(test));

            let tare_value=0;
            if(test.display_state.on_tare){
                tare_value = test.display_state.tare_value;
            }

            let ret = {};

            //calcolo i picchi
            // ret.record_peak_calculated = this.get_value_calculation(test.record_peak, test.sensor_state)-tare_value;
            // ret.record_peak_converted = +this.get_value_converted(ret.record_peak_calculated, test.sensor_state);

            // ret.record_peak_minus_calculated = this.get_value_calculation(test.record_peak_minus, test.sensor_state)-tare_value;
            // ret.record_peak_minus_converted = +this.get_value_converted(ret.record_peak_minus_calculated, test.sensor_state);
                        
            let peak_plus = 0;
            let peak_plus_ms=0;
            let peak_plus_converted=0;
            let peak_plus_converted_ms=0;
            
            let peak_minus = 0;
            let peak_minus_ms=0;
            let peak_minus_converted=0;
            let peak_minus_converted_ms=0;
            
            

            //rifaccio il grafico con i dati reali
            let data = [];
            let data_converted = [];
            let i;
            let time_span = +test.record_timestamp_start;
            // console.log('time_stamp:'+time_span);
            for(i = 0; i < test.records.length; i++){
                let record = test.records[i];
                // console.log('record:'+record[0]);
                // console.log(+record[0]-time_span);
                let _timestamp = record[0]-time_span;
                let _value = +this.get_value_calculation(record[1], test.sensor_state)-tare_value;
                data.push([+_timestamp, _value]);
                let _value_converted = +this.get_value_converted(_value, test.sensor_state);
                data_converted.push([+_timestamp, _value_converted ])
                
                //picchi
                if(!peak_plus || peak_plus < _value){
                    peak_plus = _value;
                    peak_plus_ms = _timestamp;
                }

                if(!peak_minus || peak_minus > _value){
                    peak_minus = _value;
                    peak_minus_ms = _timestamp;
                }

                if(!peak_plus_converted || peak_plus_converted < _value_converted){
                    peak_plus_converted = _value_converted;
                    peak_plus_converted_ms = _timestamp;
                }

                if(!peak_minus_converted || peak_minus_converted > _value_converted){
                    peak_minus_converted = _value_converted;
                    peak_minus_converted_ms = _timestamp;
                }

            }

            ret.data = data;
            ret.data_converted = data_converted;

            ret.record_peak_calculated = peak_plus;
            ret.record_peak_converted = peak_plus_converted;
            ret.record_peak_ms = peak_plus_ms;
            ret.record_peak_converted_ms = peak_plus_converted_ms;

            ret.record_peak_minus_calculated = peak_minus;
            ret.record_peak_minus_converted = peak_minus_converted;
            ret.record_peak_minus_ms = peak_minus_ms;
            ret.record_peak_minus_converted_ms = peak_minus_converted_ms;

            return ret;
        },
        testToCsv(test){
            let lines = [];
            let ret = this.testToRecords(test);
            lines.push('Time (ms);'+test.sensor_state.um_map[test.sensor_state.um]+';'+test.sensor_state.custom_conversion.um);
            for(let i in ret.data){
                lines.push(ret.data[i][0]+';'+ret.data[i][1]+';'+ret.data_converted[i][1]);
            }
            let csv_text = lines.join("\n").replace(/(^\[)|(\]$)/gm, "");
            return csv_text;
        },
        _number_format: function(number, decimals, dec_point, thousands_sep) {
            // Strip all characters but numerical ones.
            thousands_sep = '';
            number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + Math.round(n * k) / k;
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || '').length < prec) {
                s[1] = s[1] || '';
                s[1] += new Array(prec - s[1].length + 1).join('0');
            }
            return s.join(dec);
        }
    }
}