<template>
    <div style="max-height: 60vh; overflow-y: auto;" class="mb-3">
        <table class="table table-sm table-bordered" id="testList">
            <tr>
                <th style="width: 50%">Name</th>
                <th>Peak 1</th>
                <th>Peak 2</th>
            </tr>
            <template v-for="item in user.test_library" :key="item.id">
            <tr>
                <td style="border: 1px dashed #efefef;">
                    <strong>{{ item.name }}</strong><br />
                    <small class="text-muted">{{ item.date }}</small>
                </td>
                <td style="border: 1px dashed #efefef;">
                    <i class="icofont-arrow-up"></i> {{ itemUm(item) }} {{ itemPeak(item) }} <br />
                    <i class="icofont-arrow-down"></i> {{ itemUm(item) }} {{ itemPeakMinus(item) }}
                </td>
                <td style="border: 1px dashed #efefef;">
                    <i class="icofont-arrow-up"></i> {{ item.sensor_state.custom_conversion.um }} {{ itemPeakConverted(item) }}<br />
                    <i class="icofont-arrow-down"></i> {{ item.sensor_state.custom_conversion.um }} {{ itemPeakMinusConverted(item) }}
                </td>
            </tr>
            <tr>
                <td class="text-right" colspan="3" style="border-top: 1px dashed #ddd!important;">
                    <div class="btn-group" role="group">
                        <a @click="loadTest(item.id)" class="btn btn-outline-primary btn-sm">Load Details</a>
                        <a @click="removeTest(item.id)" class="btn btn-outline-primary btn-sm">Delete</a>
                        <a @click="exportTest(item.id)" class="btn btn-outline-primary btn-sm">Export</a>
                        <a @click="exportCsv(item.id)" class="btn btn-outline-primary btn-sm">CSV</a>
                    </div>
                </td>
            </tr>
            </template>
        </table>
    </div>
    <a @click="$refs.fileInput.click()" class="btn btn-primary btn-sm">Import</a>
    <input ref="fileInput" type="file" @change="importTest" style="display: none">
</template>
<script>
import { UserMixin } from '@/mixin/user.js'
import { SensorMixin } from '@/mixin/sensor.js'
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'TestArchive',
  mixins: [ UserMixin, SensorMixin ],
  data: function(){
    return {
    }
  },
  computed: {
    ...mapFields([
        'user'
    ]),
  },
  components:{
  },
  methods: {
      itemUm(item){
          return item.sensor_state.um_map[item.sensor_state.um];
      },
      itemPeak(item){
          return this._number_format(item.record_peak_calculated, item.display_state.resolution_points);
      },
      itemPeakConverted(item){
          return item.record_peak_converted ? this._number_format(item.record_peak_converted, item.sensor_state.custom_conversion.resolution) : '';
      },
      itemPeakMinus(item){
          return this._number_format(item.record_peak_minus_calculated, item.display_state.resolution_points);
      },
      itemPeakMinusConverted(item){
          return item.record_peak_converted ? this._number_format(item.record_peak_minus_converted, item.sensor_state.custom_conversion.resolution) : '';
      },
      loadTest(id){
          let test_library = JSON.parse(localStorage.getItem('test_library')) || {};
          if(typeof test_library[id] !== 'undefined'){
              this.$store.commit('setTestingDetails', test_library[id]);
          }
      },
      removeTest(id){
        if(!confirm('Are you sure?')) return;
        let test_library = JSON.parse(localStorage.getItem('test_library')) || {};
        let test_library_updated = {};
        for(let test_id in test_library){
            if(test_id != id){
                test_library_updated[test_id] = test_library[test_id];
            }
        }
        localStorage.setItem('test_library', JSON.stringify(test_library_updated));
        this.$store.commit('updateTestLibrary');
      },
      exportTest(id){
            let test_library = JSON.stringify(this.$store.state.user.test_library[id]);
            var element = document.createElement('a');
            element.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(test_library));
            element.setAttribute('download', this.$store.state.user.test_library[id].name+'.json');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
      },
      exportCsv(id){
            let test_library = JSON.stringify(this.$store.state.user.test_library[id]);
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += this.testToCsv(this.$store.state.user.test_library[id]);

            const data = encodeURI(csvContent);
            const link = document.createElement("a");
            link.setAttribute("href", data);
            link.setAttribute("download", (test_library.name || 'test' )+".csv");
            link.click();
      },
      importTest(event){
            // console.log(event.target.files);
            if (!event.target.files[0])
                return undefined;
            let file = event.target.files[0];
            let fr = new FileReader();
            var self = this;
            fr.onload = function() {
                let test = JSON.parse(fr.result);
                // console.log(test);
                if(typeof test.id == 'undefined'){
                    alert('Not valid file');
                    return;
                }
                let test_library = JSON.parse(localStorage.getItem('test_library')) || {};
                test_library.[test.id] = test;
                localStorage.setItem('test_library', JSON.stringify(test_library));
                self.$store.commit('updateTestLibrary')
            };
            fr.readAsText(file);
            event.target.value = '';
            
      },
      onFileLoad(elementId, event) {
            document.getElementById(elementId).innerText = event.target.result;
        },
      onChooseFile(event, onLoadFileHandler) {
            if (typeof window.FileReader !== 'function')
                throw ("The file API isn't supported on this browser.");
            let input = event.target;
            if (!input)
                throw ("The browser does not properly implement the event object");
            if (!input.files)
                throw ("This browser does not support the `files` property of the file input.");
            if (!input.files[0])
                return undefined;
            let file = input.files[0];
            let fr = new FileReader();
            fr.onload = onLoadFileHandler;
            fr.readAsText(file);
        },
      cloudSync(){

      }
  },
  created: function() {
        this.$store.commit('updateTestLibrary');
  }
}
</script>