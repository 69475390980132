<template>
    <form @submit="submit" v-if="isGuest" class="form-inline">
        <input type="email" class="form-control form-control-sm" v-model="email" id="userEmail" placeholder="Email">
        <input type="password" v-model="password" class="form-control form-control-sm" id="userPwd" placeholder="password">
        <button type="submit" class="btn btn-primary btn-sm">Login</button>
    </form>
    <div v-if="!isGuest">
        <div>
            <small class="mr-3" style="color: #ddd;">{{ user.email }} / {{ user.role }}</small>
            <a class="btn btn-primary btn-sm" @click="logout">Logout</a>
        </div>
    </div>
</template>
<script>
import { UserMixin } from '@/mixin/user.js'
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Account',
  data(){
      return {
        email: '',
        password: ''
      }
  },
  mixins: [ UserMixin ],
  computed: {
    ...mapFields([
        'user'
    ]),
  },
  methods: {
      submit(){
          this.login({email: this.email, password: this.password});
      }
  }
}
</script>
<style>
</style>