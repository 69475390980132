<template>
  <div class="testParams" style="position: relative; padding: 15px; background: #E9ECEF; border: 1px solid #CED4DA;">
    <div v-if="recording" class="d-flex align-items-center justify-content-center" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255, 255, 255,.4); z-index: 10000;"><label style="background: rgba(0,0,0,.9); color: #fff; padding: 10px;">Recording</label></div>
    <div class="row">
      <div class="col-12 mb-3">
        <label for="testname">Test Name</label>
        <div class="input-group input-group-sm mb-1">
            <input type="text" class="form-control form-control-sm" v-model="testing.name" />
            <div v-if="testing.records.length > 0 && !testing.recording" class="input-group-append">
                <a @click="saveTestToLibrary" class="btn btn-primary btn-sm ">Save Test</a>
            </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>Start recording</label>
      </div>
      <div class="col-md-4">
        <select class="form-control form-control-sm" v-model="testing.testing_conditions.start.when">
          <option value="1">ON PRESS START</option>
          <option value="2">WHEN</option>
        </select>
      </div>
      <div class="col-md-3" v-if="testing.testing_conditions.start.when==2">
        <select class="form-control form-control-sm" v-model="testing.testing_conditions.start.target">
          <option v-if="sensor.um" value="um">{{ currentUM }}</option>
          <option v-if="sensor.custom_conversion.um" value="custom_conversion">{{ sensor.custom_conversion.um }}</option>
        </select>
      </div>
      <div class="col-md-2" v-if="testing.testing_conditions.start.when==2">
        <select class="form-control form-control-sm" v-model="testing.testing_conditions.start.compare">
          <option value=">">&gt;</option>
          <option value="<">&lt;</option>
        </select>
      </div>
      <div class="col-md-3" v-if="testing.testing_conditions.start.when==2">
        <input type="text" class="form-control form-control-sm" v-model="testing.testing_conditions.start.value" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>End recording</label>
      </div>
      <div class="col-md-4">
        <select class="form-control form-control-sm" v-model="testing.testing_conditions.end.when">
          <option value="1">ON PRESS STOP</option>
          <option value="2">WHEN</option>
          <option value="3">AFTER</option>
          <option value="4">DIGIT PEAK -%</option>
        </select>
      </div>
      <div class="col-md-3" v-if="testing.testing_conditions.end.when==2">
        <select class="form-control form-control-sm" v-model="testing.testing_conditions.end.target">
          <option v-if="sensor.um" value="um">{{ currentUM }}</option>
          <option v-if="sensor.custom_conversion.um" value="custom_conversion">{{ sensor.custom_conversion.um }}</option>
        </select>
      </div>
      <div class="col-md-2" v-if="testing.testing_conditions.end.when==2">
        <select class="form-control form-control-sm" v-model="testing.testing_conditions.end.compare">
          <option value=">">&gt;</option>
          <option value="<">&lt;</option>
        </select>
      </div>
      <div class="col-md-3" v-if="testing.testing_conditions.end.when==2">
        <input type="text" class="form-control form-control-sm" v-model="testing.testing_conditions.end.value" />
      </div>
      <div class="col-md-6" v-if="testing.testing_conditions.end.when==3">
        <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
                <span class="input-group-text">ms</span>
            </div>
            <input type="number" step="1" class="form-control" v-model="testing.testing_conditions.end.after_ms">
        </div>
      </div>
      <div class="col-md-6" v-if="testing.testing_conditions.end.when==4">
        <div class="input-group input-group-sm mb-1">
            <div class="input-group-prepend">
                <span class="input-group-text">-%</span>
            </div>
            <input type="number" step="1" class="form-control" v-model="testing.testing_conditions.end.peak_perc">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mt-3">
      <button class="btn btn-primary mr-1 mb-1" @click="start()" v-if="!testing.recording">Start</button>
      <button class="btn btn-primary mr-1 mb-1" @click="stop()" v-if="testing.recording">Stop</button>
    </div>
    <div class="col-sm-12 mt-3">
      <div v-if="testing.records.length > 0 && !testing.recording">
        <table class="table table-sm">
          <tr>
            <th>Records</th>
            <td>{{ recordsLength }}</td>
            <td>
                <a @click="exportCsv" class="btn btn-outline-primary btn-sm mr-1">Export CSV</a>
                <a @click="exportPdf" class="btn btn-outline-primary btn-sm mr-1">Report PDF</a>
            </td>
          </tr>
          <tr v-if="false && recordsFreq">
            <th>Real Freq.</th>
            <td colspan="2">{{ recordsFreq }}</td>
          </tr>
          <tr v-if="peakCalculated">
            <th>{{ um_str }} Peak</th>
            <td><i class="icofont-arrow-up"></i> {{ peakCalculated }} {{ um_str }}</td>
            <td><i class="icofont-arrow-down"></i> {{ peakMinusCalculated }} {{ um_str }}</td>
          </tr>
          <tr v-if="has_conversion">
            <th>{{ um_conversion }} Peak</th>
            <td><i class="icofont-arrow-up"></i> {{ peakConverted }} {{ um_conversion }}</td>
            <td><i class="icofont-arrow-down"></i> {{ peakMinusConverted }} {{ um_conversion }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="!pdf_preview"
        :preview-modal="pdf_preview"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="html2Pdf"
        v-if="testing.records.length > 0 && !testing.recording"
    >
        <template v-slot:pdf-content>
            <div class="p-5" id="pdf-content" style="width: 800px!important; height: 1100px!important; overflow: hidden;">
                <div id="pdf_header" class="row mb-3">
                    <div class="col-3" v-if="pdf_logo">
                        <img :src="pdf_logo" class="img-fluid" />
                    </div>
                    <div class="col-9">
                    <div v-html="pdf_header"></div>
                    </div>
                </div>
                <h1 style="text-align: center; font-weight: bold;">TEST REPORT</h1>
                <table class="table table-sm my-5">
                    <tr>
                        <th>{{ testing.name }}</th>
                        <td>{{ testing.date }}</td>
                        <td>
                        </td>
                    </tr>
                    <tr>
                        <th>{{ um_str }} Peak</th>
                        <td><i class="icofont-arrow-up"></i> {{ peakCalculated }} {{ um_str }}</td>
                        <td><i class="icofont-arrow-down"></i> {{ peakMinusCalculated }} {{ um_str }}</td>
                    </tr>
                    <tr v-if="has_conversion">
                        <th>{{ um_conversion }} Peak</th>
                        <td><i class="icofont-arrow-up"></i> {{ peakConverted }} {{ um_conversion }}</td>
                        <td><i class="icofont-arrow-down"></i> {{ peakMinusConverted }} {{ um_conversion }}</td>
                    </tr>
                </table>
                <TestChart stroke-color="#000" />
                <div class="row mt-5">
                    <div class="col-3">
                        <h5>Date</h5>
                        <br />
                        <hr style="border-color: #000" />
                    </div>
                    <div class="col-6"></div>
                    <div class="col-3">
                        <h5>Signature</h5>
                        <br />
                        <hr style="border-color: #000" />
                    </div>
                </div>
                <div id="pdf_footer" class="text-center mt-5">
                    <div v-html="pdf_footer"></div>
                </div>
            </div>
        </template>
    </vue3-html2pdf>
</template>
<script>
import { SensorMixin } from '@/mixin/sensor.js';
import { BleMixin } from '@/mixin/ble.js';
import { mapFields } from 'vuex-map-fields';
import Vue3Html2pdf from 'vue3-html2pdf'
import TestChart from '@/components/TestChart.vue'

export default {
  name: 'TestArea',
  mixins: [ SensorMixin, BleMixin ],
  components: { Vue3Html2pdf, TestChart },
  data(){
    return {
      chartTimer: null,
      htmlToPdfOptions: {
            filename: 'test',
            html2canvas: {
                useCORS: true
            }
        }
    }
  },
  computed: {
    ...mapFields([
          'testing', 'user', 'sensor'
    ]),
    recording(){
      return this.$store.state.testing.recording
    },
    recordsLength(){
      return this.$store.state.testing.records.length;
    },
    currentUM(){
      return this.$store.state.sensor.um_map[this.$store.state.sensor.um];
    },
    recordsFreq(){
      // let _length = this.$store.state.testing.records.length -1;
      // if(_length > 51){
      //   let _freq = ( 50 / ( this.$store.state.testing.records[_length][0] - this.$store.state.testing.records[_length-50][0] ) ) * 1000;
      //   return this._number_format(_freq, 2)+'Hz';
      // }else return false;
      let test = this.$store.state.testing;
      let freq = +test.records.length / ( +test.latest_record_timestamp - +test.record_timestamp_start );
      freq = freq*1000;
      return this._number_format(freq, 3) + 'Hz';
    },
    peakCalculated(){
      return this._number_format(this.$store.state.testing.record_peak_calculated, this.$store.state.testing.display_state.resolution_points);
    },
    peakMinusCalculated(){
      return this._number_format(this.$store.state.testing.record_peak_minus_calculated, this.$store.state.testing.display_state.resolution_points);
    },
    peakConverted(){
      return this._number_format(this.$store.state.testing.record_peak_converted, this.$store.state.testing.sensor_state.custom_conversion.resolution);
    },
    peakMinusConverted(){
      return this._number_format(this.$store.state.testing.record_peak_minus_converted, this.$store.state.testing.sensor_state.custom_conversion.resolution);
    },
    startListening(){
      return this.$store.state.testing.start_listening
    },
    isRecording(){
      return this.$store.state.testing.recording
    },
    has_conversion(){
        return this.$store.state.testing.sensor_state.custom_conversion.formula;
    },
    pdf_logo(){
        return this.$store.state.report_settings.logo_url;
    },
    pdf_header(){
        return this.$store.state.report_settings.header_data;
    },
    pdf_footer(){
        return this.$store.state.report_settings.footer_data;
    },
    pdf_preview(){
        return this.$store.state.report_settings.preview || false;
    }
  },
  methods: {
    start(){
    let self = this;
    // this.write(Uint8Array.of(6)).then(function(){ //resettiamo il timestamp tanto per
    
    // });
    let test_date = new Date().toJSON().slice(0, 19).replace('T', ' ');
    self.$store.commit('setTestingProperty', {
        key: 'date',
        value: test_date
    });
    self.$store.commit('setTestingProperty', {
        key: 'recording',
        value: true
    });
    self.$store.commit('setTestingProperty', {
        key: 'record_peak',
        value: 0
    });
    self.$store.commit('setTestingProperty', {
        key: 'record_peak_calculated',
        value: 0
    });
    self.$store.commit('setTestingProperty', {
        key: 'record_peak_converted',
        value: 0
    });
    self.$store.commit('setTestingProperty', {
        key: 'start_listening',
        value: true
    })
    self.$store.commit('setTestingProperty', {
        key: 'records',
        value: []
    });
    self.$store.commit('setTestingProperty', {
        key: 'record_timestamp_start',
        value: false
    });
    self.$store.commit('setTestingProperty', {
        key: 'sensor_state',
        value: JSON.parse(JSON.stringify(self.$store.state.sensor))
    });
    self.$store.commit('setTestingProperty', {
        key: 'display_state',
        value: JSON.parse(JSON.stringify(self.$store.state.display))
    });
    self.$store.commit('setChartProperty', {
        key: 'data',
        value: []
    });
    self.$store.commit('setTestingDetails', null);
    
      this.chartTimer = setInterval(function(){
        let _latest_index = self.$store.state.testing.records.length -1;
        if(_latest_index >= 0){
          let latest = self.$store.state.testing.records[_latest_index];
          if(self.$store.state.chart.latest_timestamp == latest[0]) return;
          self.$store.state.chart.latest_timestamp = latest[0];
          self.$store.state.chart.data.push([ self.$store.state.calculated_value_timestamp - self.$store.state.testing.record_timestamp_start, self.$store.state.display_value ]);
        }
      }, 250);
    },
    stop(){
        // if(!this.$store.state.testing.recording) return;
        this.$store.commit('setTestingProperty', {
            key: 'recording',
            value: false
        });
        clearInterval(this.chartTimer);

        let ret = this.testToRecords(this.$store.state.testing);

        this.$store.state.testing.record_peak_calculated = ret.record_peak_calculated;
        this.$store.state.testing.record_peak_converted = ret.record_peak_converted;
        this.$store.state.testing.record_peak_minus_calculated = ret.record_peak_minus_calculated;
        this.$store.state.testing.record_peak_minus_converted = ret.record_peak_minus_converted;

        //correggiamo eventuali altri picchi trovati
        this.$store.commit('setDisplayProperty', {
            key: 'peak_plus_value',
            value: ret.record_peak_calculated
        });
        this.$store.commit('setDisplayProperty', {
            key: 'peak_minus_value',
            value: ret.record_peak_minus_calculated
        });
        
        this.$store.commit('setChartProperty', {
            key: 'data',
            value: ret.data
        });

    },
    saveTestToLibrary(){
        let test_library = JSON.parse(localStorage.getItem('test_library')) || {};
        if(!this.$store.state.testing.name){
            alert('Please specific a test name');
            return null;
        }
        let test_id = new Date().getTime();
        test_id += +Math.random();
        test_library[test_id] = JSON.parse(JSON.stringify(this.$store.state.testing));
        test_library[test_id].id = test_id;
        // test_library[test_id].date = new Date().toJSON().slice(0, 19).replace('T', ' ');
        localStorage.setItem('test_library', JSON.stringify(test_library));
        this.$store.commit('updateTestLibrary');
    },
    exportCsv(){
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += this.testToCsv(this.$store.state.testing);

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", (this.$store.state.testing.name || 'test' )+".csv");
        link.click();
    },
    exportPdf () {
        document.querySelector("#app-overlay").style.display='flex';
        document.querySelector("meta[name='viewport']").setAttribute("content", 'width=1400,user-scalable=no,viewport-fit=cover');
        this.$refs.html2Pdf.generatePdf();
        setTimeout(function(){
            document.querySelector("#app-overlay").style.display='none';
            document.querySelector("meta[name='viewport']").setAttribute("content", 'width=device-width,user-scalable=no,viewport-fit=cover');
        }, 3000)
    }
  },
  watch: {
    isRecording (newVal, oldVal) {
      if(!newVal && oldVal){
        this.stop();
      }
      
    }
  }
}
</script>
<style>
.apexcharts-menu-item.exportCSV,
.apexcharts-pan-icon{
    display: none;
}
#pdf-content .apexcharts-toolbar{
    display: none;
}
</style>