<template>
  <div>
    <h5 class="text-center">{{ testName }}</h5>
    <ApexCharts
      type="line"
      :options="chartOpts"
      :series="chartSerie"
    ></ApexCharts>
  </div>
</template>

<script>
//https://www.npmjs.com/package/vue3-apexcharts
import ApexCharts from 'vue3-apexcharts';
import { SensorMixin } from '@/mixin/sensor.js'

export default {
  name: 'TestChart',
  components: {
    ApexCharts,
  },
  mixins: [ SensorMixin ],
  props: ['strokeColor'],
  computed: {
    testName(){
      return this.$store.state.testing.name;
    },
    chartSerie(){
      return [{
        name: this.$store.state.sensor.um_map[this.$store.state.sensor.um],
        data: this.$store.state.chart.data
      }]      
    },
    chartOpts(){
      return {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            export: {
            //   csv: {
            //     filename: this.$store.state.ble.device_name,
            //     headerCategory: 'Time (ms)',
            //     headerValue: this.$store.state.sensor.um_map[this.$store.state.sensor.um],
            //     columnDelimiter: ';'
            //   },
              svg: {
                filename: this.$store.state.ble.device_name,
              },
              png: {
                filename: this.$store.state.ble.device_name,
              }
            }
          }
        },
        yaxis: {
          title: {
            text: this.$store.state.sensor.um_map[this.$store.state.sensor.um]
          },
          decimalsInFloat: this.$store.state.display.resolution_points
        },
        xaxis: {
          type: 'time',
          tickAmount: 5,
          title: {
            text: 'Time (ms)'
          },
          labels: {
            offsetY: 5,
            formatter: function(value) {
              return parseInt(value);
            }
          }
          
        },
        stroke: {
            show: true,
            // curve: 'smooth',
            // lineCap: 'butt',
            colors: [this.strokeColorData],
            width: 2,
            dashArray: 0,      
        }
      }
    },
    strokeColorData(){
        return this.strokeColor || '#0000FF';
    }
  },
  methods: {
      updatChart(){
        this.series[0].data = this.$store.state.chart.data;
      }
  },
  data: function() {
    return {
      series: [
        {
          data: [],
        },
      ],
    };
  },
}
</script>
<style>
.apexcharts-menu-item.exportCSV,
.apexcharts-pan-icon{
    display: none;
}
</style>