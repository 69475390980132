<template>
  <div>
    <h5 class="text-center">{{ testName }}</h5>
    <ApexCharts
      type="line"
      :options="chartOpts"
      :series="chartSerie"
    ></ApexCharts>
  </div>
  <table class="table table-sm">
    <tr>
        <th>Records</th>
        <td>{{ recordsLength }}</td>
        <td>
            <a @click="exportCsv" class="btn btn-outline-primary btn-sm mr-1">Export CSV</a>
            <a @click="exportPdf" class="btn btn-outline-primary btn-sm mr-1">Report PDF</a>
        </td>
    </tr>
    <tr v-if="has_conversion">
        <th>{{ um_str }} Peak</th>
        <td><i class="icofont-arrow-up"></i> {{ peakCalculated }} {{ um_str }}</td>
        <td><i class="icofont-arrow-down"></i> {{ peakMinusCalculated }} {{ um_str }}</td>
      </tr>
      <tr v-if="peakConverted">
        <th>{{ um_conversion }} Peak</th>
        <td><i class="icofont-arrow-up"></i> {{ peakConverted }} {{ um_conversion }}</td>
        <td><i class="icofont-arrow-down"></i> {{ peakMinusConverted }} {{ um_conversion }}</td>
      </tr>
  </table>
<div>
     <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="!pdf_preview"
        :preview-modal="pdf_preview"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="portrait"
        pdf-content-width="800px"
        ref="html2Pdf"
    >
        <template v-slot:pdf-content>
            <div class="p-5" id="pdf-content" style="width: 800px!important; height: 1100px!important; overflow: hidden;">
                <div id="pdf_myheader">
                    <div class="row mb-3">
                        <div class="col-3" v-if="pdf_logo">
                            <img :src="pdf_logo" class="img-fluid" />
                        </div>
                        <div class="col-9">
                        <div v-html="pdf_header"></div>
                        </div>
                    </div>
                </div>
                <div id="pdf_mycontent">
                    <h1 style="text-align: center; font-weight: bold;">TEST REPORT</h1>
                    <table class="table table-sm my-5">
                        <tr>
                            <th>{{ testName }}</th>
                            <td>{{ test.date }}</td>
                            <td>
                            </td>
                        </tr>
                        <tr v-if="peakCalculated">
                            <th>{{ um_str }} Peak</th>
                            <td><i class="icofont-arrow-up"></i> {{ peakCalculated }} {{ um_str }}</td>
                            <td><i class="icofont-arrow-down"></i> {{ peakMinusCalculated }} {{ um_str }}</td>
                        </tr>
                        <tr v-if="has_conversion">
                            <th>{{ um_conversion }} Peak</th>
                            <td><i class="icofont-arrow-up"></i> {{ peakConverted }} {{ um_conversion }}</td>
                            <td><i class="icofont-arrow-down"></i> {{ peakMinusConverted }} {{ um_conversion }}</td>
                        </tr>
                    </table>
                    <h5 class="text-center">{{ testName }}</h5>
                    <ApexCharts
                    type="line"
                    :options="chartOpts"
                    :series="chartSerie"
                    ></ApexCharts>
                    <div class="row mt-5">
                        <div class="col-3">
                            <h5>Date</h5>
                            <br />
                            <hr style="border-color: #000" />
                        </div>
                        <div class="col-6"></div>
                        <div class="col-3">
                            <h5>Signature</h5>
                            <br />
                            <hr style="border-color: #000" />
                        </div>
                    </div>
                </div>
                <div id="pdf_myfooter">
                    <div class="text-center mt-5">
                        <div v-html="pdf_footer"></div>
                    </div>
                </div>
            </div>
        </template>
    </vue3-html2pdf>
   </div>
</template>

<script>
//https://www.npmjs.com/package/vue3-apexcharts
import ApexCharts from 'vue3-apexcharts';
import { SensorMixin } from '@/mixin/sensor.js';
import Vue3Html2pdf from 'vue3-html2pdf'

export default {
  name: 'TestDetail',
  components: {
    ApexCharts, Vue3Html2pdf
  },
  mixins: [ SensorMixin ],
  props: ['test'],
  computed: {
    // ...mapFields([
    //     'test_details'
    // ]),
    testName(){
      return this.test.name;
    },
    chartSerie(){
      let ret = this.testToRecords(this.test);
      return [{
        name: this.test.sensor_state.um_map[this.test.sensor_state.um],
        data: ret.data
      }]      
    },
    recordsLength(){
      return this.test.records.length;
    },
    peakCalculated(){
      return this._number_format(this.test.record_peak_calculated, this.test.display_state.resolution_points);
    },
    peakMinusCalculated(){
      return this._number_format(this.test.record_peak_minus_calculated, this.test.display_state.resolution_points);
    },
    peakConverted(){
      return this._number_format(this.test.record_peak_converted, this.test.sensor_state.custom_conversion.resolution);
    },
    peakMinusConverted(){
      return this._number_format(this.test.record_peak_minus_converted, this.test.sensor_state.custom_conversion.resolution);
    },
    um_str(){
      return this.test.sensor_state.um_map[this.test.sensor_state.um];
    },
    um_conversion(){
      return this.test.sensor_state.custom_conversion.um;
    },
    has_conversion(){
        return this.test.sensor_state.custom_conversion.formula != '';
    },
    chartOpts(){
      return {
        chart: {
          animations: {
            enabled: false,
          },
          toolbar: {
            export: {
            //   csv: {
            //     filename: this.test.name,
            //     headerCategory: 'timestamp',
            //     headerValue: this.test.sensor_state.um_map[this.test.sensor_state.um],
            //     columnDelimiter: ';'
            //   },
              svg: {
                filename: this.test.name,
              },
              png: {
                filename: this.test.name,
              }
            }
          }
        },
        yaxis: {
          title: {
            text: this.test.sensor_state.um_map[this.test.sensor_state.um]
          },
          decimalsInFloat: this.test.display_state.resolution_points
        },
        xaxis: {
          type: 'time',
          tickAmount: 5,
          title: {
            text: 'Time (ms)'
          },
          labels: {
            offsetY: 5,
            formatter: function(value) {
              return parseInt(value);
            }
          }
        },
        stroke: {
            show: true,
            // curve: 'smooth',
            // lineCap: 'butt',
            colors: ['#000000'],
            width: 2,
            dashArray: 0,      
        }
      }
    },
    pdf_logo(){
        return this.$store.state.report_settings.logo_url;
    },
    pdf_header(){
        return this.$store.state.report_settings.header_data;
    },
    pdf_footer(){
        return this.$store.state.report_settings.footer_data;
    },
    pdf_preview(){
        return this.$store.state.report_settings.preview || false;
    }
  },
  data: function() {
    return {
      series: [
        {
          data: [],
        },
      ],
      htmlToPdfOptions: {
            filename: 'test',
            html2canvas: {
                useCORS: true
            }
        }
    };
  },
  methods: {
    exportCsv(){
        let csvContent = "data:text/csv;charset=utf-8,";
        csvContent += this.testToCsv(this.test);

        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", (this.test.name || 'test' )+".csv");
        link.click();
    },
    exportPdf () {
        document.querySelector("#app-overlay").style.display='flex';
        document.querySelector("meta[name='viewport']").setAttribute("content", 'width=1400,user-scalable=no,viewport-fit=cover');
        this.$refs.html2Pdf.generatePdf();
        setTimeout(function(){
            document.querySelector("#app-overlay").style.display='none';
            document.querySelector("meta[name='viewport']").setAttribute("content", 'width=device-width,user-scalable=no,viewport-fit=cover');
        }, 3000)
    }
  }
}
</script>
<style>
.apexcharts-menu-item.exportCSV,
.apexcharts-pan-icon{
    display: none;
}
#pdf-content .apexcharts-toolbar{
    display: none;
}
</style>