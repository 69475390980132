import axios from 'axios';
import { mapState } from 'vuex';
import qs from 'qs';

export const UserMixin = {
    data() {
        return {
            API_URL: 'https://api.devitech.it/'
        }
    },
    computed: {
        isLogged(){
            return this.$store.state.user.logged;
        },
        isGuest(){
            return !this.$store.state.user.logged;
        },
        userToken(){
            return this.$store.state.user.token;
        },
        ...mapState([])
    },
    methods: {
        login(user) {
            let self=this;
            return axios
                .post(this.API_URL + 'auth', qs.stringify({
                    email: user.email,
                    password: user.password,
                }))
                .then(response => {
                    if (response.data.token) {
                        localStorage.setItem('user', JSON.stringify(response.data));
                    }
                    self.$store.commit('updateUserData');
                    return response.data;
                })
                .catch(function(error){
                    return error;
                });
        },
        logout() {
            let self=this;
            localStorage.removeItem('user');
            self.$store.commit('updateUserData');
        },
        register() {},
        isRole(role){
            return this.$store.state.user.role == role;
        },
        authHeader() {
            let userToken = JSON.parse(localStorage.getItem('userToken'));
            if (userToken) {
                return {
                    Authorization: 'Bearer ' + userToken
                };
            } else {
                return {};
            }
        }
    }
}