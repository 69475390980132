<template>
  <div class="mb-3">
    <nav>
        <div class="nav nav-tabs" id="nav-tab-sensor" role="tablist">
            <a :class="'nav-item nav-link '+(bleReceiving?'active':'')" id="nav-SensorData-tab" data-toggle="tab" href="#nav-SensorData" role="tab" aria-controls="nav-SensorData" aria-selected="true" ref="sensorTab" v-if="bleReceiving">Sensor</a>
            <a class="nav-item nav-link" id="nav-Linearization-tab" data-toggle="tab" href="#nav-Linearization" role="tab" aria-controls="nav-Linearization" aria-selected="false" v-if="!safeMode">Calibration</a>
            <a :class="'nav-item nav-link '+(bleReceiving?'':'active')" id="nav-LibrarySensor-tab" data-toggle="tab" href="#nav-LibrarySensor" role="tab" aria-controls="nav-LibrarySensor" aria-selected="false">Sensors Library</a>
            <a class="nav-item nav-link" id="nav-FactorySettings-tab" data-toggle="tab" href="#nav-FactorySettings" role="tab" aria-controls="nav-FactorySettings" aria-selected="false" v-if="userFactory && bleReceiving">Factory</a>
        </div>
    </nav>
    <div class="tab-content" id="nav-tabContentSensor">
        <div v-if="recording" class="d-flex align-items-center justify-content-center" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(255, 255, 255,.4); z-index: 10000;"><label style="background: rgba(0,0,0,.9); color: #fff; padding: 10px;">Recording</label></div>
        <div :class="'tab-pane fade py-2 '+(bleReceiving?'show active':'')" id="nav-SensorData" role="tabpanel" aria-labelledby="nav-SensorData-tab" v-if="bleReceiving">
          <Display />
          <SensorData />
        </div>
        <div :class="'tab-pane fade py-2 '+(bleReceiving?'':'show active')" id="nav-LibrarySensor" role="tabpanel" aria-labelledby="nav-LibrarySensor-tab">
          <SensorLibrary />
        </div>
        <div class="tab-pane fade py-2" id="nav-Linearization" role="tabpanel" aria-labelledby="nav-Linearization-tab" v-if="!safeMode">
          <Linearization />
        </div>
        <div class="tab-pane fade py-2" id="nav-FactorySettings" role="tabpanel" aria-labelledby="nav-FactorySettings-tab" v-if="userFactory && bleReceiving">
          <FactorySettings />
        </div>
    </div>
  </div>
</template>
<script>
import SensorData from '@/components/SensorData.vue'
import Linearization from '@/components/Linearization.vue'
import Display from '@/components/Display.vue'
import SensorLibrary from '@/components/SensorLibrary.vue'
import FactorySettings from '@/components/FactorySettings.vue'

export default {
  name: 'SensorTabs',
  components: {
    SensorData, Linearization, Display, SensorLibrary, FactorySettings
  },
  computed: {
    safeMode(){
      return  this.$store.state.safe_mode;
    },
    bleReceiving(){
      return  this.$store.state.ble.receiving;
    },
    userFactory(){
      return this.$store.state.user.role == 'developer';
    },
    recording(){
      return this.$store.state.testing.recording
    }
  },
  methods: {
      
  }
}
</script>
<style>
nav{
    overflow: auto;
}
.nav.nav-tabs{
    flex-wrap: nowrap;
}
.nav-tabs .nav-link{
    white-space: nowrap;
}
</style>