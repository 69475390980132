<template>
    <div v-if="!canBLE" class="text-center p-0 m-0 mr-2 alert alert-danger">
        <i class="icofont-warning-alt"></i> BLE Connection API not available. Please use Google Chrome on Android, Windows or Linux.
    </div>
    <div v-if="canBLE && !BleConnected" class="text-center p-0 m-0 mr-3">
        <a href="#" class="btn btn-primary" @click="connect"><i class="icofont-bluetooth"></i> Connect Device</a>
    </div>
    <div v-if="BleConnected" class="p-0 pr-2 m-0 mr-2" style="line-height: 1; color: #fff; text-align: right; border-right: 1px solid rgba(255, 255, 255, .6)">
        <small><i class="icofont-bluetooth"></i> {{ bleName }}</small>
        <div class="ble_log_txt"><code><small style="color: yellow;">{{ bleLog }}</small></code></div>
    </div>
</template>
<script>
import { BleMixin } from '@/mixin/ble.js'

export default {
  name: 'Ble Connector',
  mixins: [ BleMixin ],
  computed: {
      bleLog(){
          return this.$store.state.ble.log;
      },
      bleName(){
          return this.$store.state.ble.device_name; 
      }
  }
}
</script>