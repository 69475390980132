import { mapState } from 'vuex'

export const BleMixin = {
    data(){
        return {
            can_BLE: ('bluetooth' in navigator),
            BLE_service_UID: '0003cdd0-0000-1000-8000-00805f9b0131',
            BLE_read_characteristic_UID: '0003cdd1-0000-1000-8000-00805f9b0131',
            BLE_write_characteristic_UID: '0003cdd2-0000-1000-8000-00805f9b0131'
        }
    },
    computed: {
        canBLE(){
            return this.can_BLE;
        },
        BleConnected(){
            return this.$store.state.ble.device;
        },
        ...mapState([])
    },
    methods: {
        connect(){
            let self=this;
            navigator.bluetooth.requestDevice({
                filters: [
                    {services: [ this.BLE_service_UID ]}
                ],
            }).then(function (device) {
                self.$store.commit('setBleProperty', {
                    key: 'device',
                    value: device
                });
                self.$store.commit('setBleProperty', {
                    key: 'device_name',
                    value: device.name
                });
                self.$store.commit('setBleProperty', {
                    key: 'log',
                    value: 'Connecting...'
                });
                return device.gatt.connect();
            }).then(function (server) {
                // console.log('server ',server);
                // console.log('store', self);
                self.$store.commit('setBleProperty', {
                    key: 'server',
                    value: server
                });
                return server.getPrimaryService(self.BLE_service_UID);
            }).then(function(service){
                console.log('service', service);
                self.$store.commit('setBleProperty', {
                    key: 'service',
                    value: service
                });
                return service.getCharacteristic(self.BLE_write_characteristic_UID);
            }).then(function(w_chr){
                // console.log('writing w_chr', w_chr);
                self.$store.commit('setBleProperty', {
                    key: 'w_chr',
                    value: w_chr
                });
                // w_chr.writeValue(Uint8Array.of(3)); //diamo il comando di inizializzare se non inizializzato
                self.write(Uint8Array.of(3)).then(function(){
                    self.setFreq(3);
                });
                // console.log(self.BLE_read_characteristic_UID);
                return self.$store.state.ble.service.getCharacteristic(self.BLE_read_characteristic_UID)
            }).then(function(r_chr){
                // console.log('writing r_chr', r_chr);
                self.$store.commit('setBleProperty', {
                    key: 'r_chr',
                    value: r_chr
                });
                // console.log('before notification'):
                setTimeout(function(){ //lasciare il timeout per qualche motivo va in botta su mobile se no
                    r_chr.startNotifications().then( function() {
                        // console.log('> Notifications started');
                        self.$store.commit('setBleProperty', {
                            key: 'log',
                            value: 'Notifications started'
                        });
                        r_chr.addEventListener('characteristicvaluechanged', function(event){
                            if(
                                event.target.value.getUint8() != 1 
                                || ( event.target.value.byteLength == 2 && event.target.value.getUint8() == 1 ) //caso cambio frequenza dispositivo
                            ){
                                // console.log('trigger dataviewInterpreter from listener', event.target.value);
                                self.$store.commit('dataviewRunListener', event.target.value);
                            }else{
                                self.$store.commit('setBleProperty', {
                                    key: 'log',
                                    value: 'Receiving data'
                                });
                                self.$store.commit('setBleProperty', {
                                    key: 'buffer',
                                    value: event.target.value
                                });
                                let raw_value = event.target.value.getInt32(1, false);
                                let raw_value_timestamp = event.target.value.getInt32(5, false);
                                self.$store.commit('setBleProperty', {
                                    key: 'raw_value',
                                    value: raw_value
                                }); 
                                self.$store.commit('setBleProperty', {
                                    key: 'raw_value_timestamp',
                                    value: raw_value_timestamp
                                });
                                
                                if(self.$store.state.testing.recording){
                                    let _span = 1;
                                    let _timestamp, _value;
                                    let buffer_length = event.target.value.byteLength -1;
                                    while(buffer_length > 0){
                                        _value = event.target.value.getInt32(_span, false);
                                        _timestamp = event.target.value.getInt32(_span+4, false);
                                        self.checkStartStopcondition();
                                        if(!self.$store.state.testing.start_listening){
                                            if(self.$store.state.testing.record_peak < _value){
                                                self.$store.state.testing.record_peak = _value;
                                            }
                                            if(!self.$store.state.testing.record_peak_minus || self.$store.state.testing.record_peak_minus > _value){
                                                self.$store.state.testing.record_peak_minus = _value;
                                            }
                                            // console.log('peak:', self.$store.state.testing.record_peak);
                                            if(!self.$store.state.testing.record_timestamp_start){
                                                self.$store.state.testing.record_timestamp_start = _timestamp;
                                                self.$store.state.testing.record_digits_start = _value;
                                            }
                                            self.$store.state.testing.latest_record_timestamp = _timestamp;
                                            self.$store.state.testing.records.push([ _timestamp, _value ]);
                                        }
                                        _span += 8;
                                        buffer_length -= 8;
                                    }                                    
                                }                          
                            }
                            if(!self.$store.state.ble.receiving){
                                self.$store.commit('setBleProperty', {
                                    key: 'receiving',
                                    value: true
                                });
                            }
                        });
                        
                    });
                }, 500)
                
            })
        },
        write(byte_array){
            this.$store.commit('setBleProperty', {
                key: 'log',
                value: 'Awaiting response'
            });
            return this.$store.state.ble.w_chr.writeValueWithResponse(byte_array);
        },
        setFreq(FreqIndex){
            this.$store.commit('setBleProperty', {
                key: 'freq',
                value: FreqIndex
            });
            this.write(this._appendBuffer(Uint8Array.of(1), Uint8Array.of(FreqIndex)));
        },
        checkStartStopcondition(){
            let state = this.$store.state;
            // if(!state.testing.start_listening) return;
            let conds = state.testing.testing_conditions;
            switch(conds.start.when){
                case "1":
                case 1: this.$store.state.testing.start_listening = false;
                    break;
                case "2":
                case 2: // WHEN
                    if(conds.start.target=='um'){
                        if(conds.start.compare == '>' && +state.display_value > +conds.start.value ){
                            this.$store.state.testing.start_listening = false;
                        }
                        if(conds.start.compare == '<' && +state.display_value < +conds.start.value ){
                            this.$store.state.testing.start_listening = false;
                        }
                    }
                    if(conds.start.target=='custom_conversion'){
                        if( conds.start.compare == '>' && +state.converted_value > +conds.start.value ){
                            this.$store.state.testing.start_listening = false;
                        }
                        if(conds.start.compare == '<' && +state.converted_value < +conds.start.value ){
                            this.$store.state.testing.start_listening = false;
                        }
                    } 
                    break;
            }
            // console.log('ppp', conds.end.when);
            switch(conds.end.when){
                // case "1":
                // case 1: this.$store.state.testing.start_listening = false;
                //     break;
                case "2":
                case 2: // WHEN
                    if(conds.end.target=='um'){
                        if(conds.end.compare == '>' && +state.display_value > +conds.end.value ){
                            this.$store.state.testing.recording = false;
                        }
                        if(conds.end.compare == '<' && +state.display_value < +conds.end.value ){
                            this.$store.state.testing.recording = false;
                        }
                    }
                    if(conds.end.target=='custom_conversion'){
                        if( conds.end.compare == '>' && +state.converted_value > +conds.end.value ){
                            this.$store.state.testing.recording = false;
                        }
                        if(conds.end.compare == '<' && +state.converted_value < +conds.end.value ){
                            this.$store.state.testing.recording = false;
                        }
                    }
                    break;
                case "3":
                case 3: // AFTER
                    if( this.$store.state.testing.record_timestamp_start && +conds.end.after_ms + +this.$store.state.testing.record_timestamp_start < +this.$store.state.testing.latest_record_timestamp ){
                        this.$store.state.testing.recording = false;
                        // this.$store.state.testing.record_timestamp_start = false;
                    }
                    break;
                case "4":
                case 4: // PEAK -%
                    var peak_against = Math.max( Math.abs(state.testing.record_peak), Math.abs(state.testing.record_peak))
                    var compare = ( 1 - ( conds.end.peak_perc / 100 ) ) * ( peak_against - Math.abs(state.testing.record_digits_start));

                    if( peak_against && ( Math.abs(state.ble.raw_value) - Math.abs(state.testing.record_digits_start) ) < compare ){
                        this.$store.state.testing.recording = false;
                    }
                    break;
                default: break;
            }

            this.$store.commit('setTestingProperty', {
                key: 'recording',
                value: this.$store.state.testing.recording
            });
        },
        _appendBuffer(buffer1, buffer2) {
            var tmp = new Uint8Array(buffer1.byteLength + buffer2.byteLength);
            tmp.set(new Uint8Array(buffer1), 0);
            tmp.set(new Uint8Array(buffer2), buffer1.byteLength);
            return tmp.buffer;
        },
        str2Ascii(str, length){
            if(typeof length == 'undefined') length = str.length;
            var tmp = new Uint8Array(length);
            for(let i=0; i<length; i++){
                try { tmp[i] = str[i].charCodeAt(0); }
                catch{ tmp[i] = 0; }
            }
            return tmp.buffer;
        },
        ascii2Str(dataView, start, stop){
            if(typeof start == 'undefined') start = 0;
            if(typeof stop == 'undefined') stop = dataView.byteLength;
            var s='', asciiCode;
            for(let i=start; i<stop; i++){
                asciiCode = dataView.getInt8(i);
                if( asciiCode )//per evitare spazi a zero byte
                    s += String.fromCharCode(asciiCode);
            }
            return s;
        },
        num2Byte(num, length){
            if(typeof length == 'undefined') length = num.length;
            var tmp = new Uint8Array(length);
            for(let i=0; i<length; i++){
                let shift = ( 8 * ( length - i - 1 ));
                try { tmp[i] = num >> shift; }
                catch{ tmp[i] = 0; }
            }
            return tmp;
        },
        byte2Num(byteArray){
            var n=0;
            var shift = 8 * ( byteArray.byteLength - 1 );
            n = byteArray[0] << shift
            var arr = new Uint8Array(byteArray);
            for(let i=1; i < arr.byteLength; i++){
                shift = ( 8 * ( arr.byteLength - i - 1 ));
                n += arr[i] << shift;
            }
            return n;
        },
    }
}